<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        اضافة جديد
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/system/countries')"
        />
      </template>
    </Toolbar>
    <div class="form-card">
      <div class="mb-3">
        <label for="name" class="form-label required">
          الاسم
          <span>*</span>
        </label>
        <input
          class="form-control"
          id="name"
          v-model="body.name"
          :class="{ 'is-invalid': isInvalid && !body.name }"
          required
        />
      </div>

      <div class="mb-3 text-center">
        <Button
          label="تعديل"
          icon="pi pi-pencil"
          v-tooltip="'تعديل'"
          class="p-ml-2 p-button-info"
          @click="update()"
          v-if="id"
          :disabled="disabledAdd"
        />
        <Button
          v-else
          label="اضافة جديد"
          icon="pi pi-plus"
          v-tooltip="'اضافه جديد'"
          class="p-ml-2 p-button-success"
          @click="save()"
          :disabled="disabledAdd"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        name: null,
      },
      id: null,
      isInvalid: false,
      disabledAdd: false,
    };
  },
  methods: {
    save() {
      if (this.body.name) {
        this.disabledAdd = true;
        this.$http.post(`countries`, this.body).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم الاضافة بنجاح',
              life: 3000,
            });
            this.disabledAdd = false;

            this.$router.push('/admin/system/countries');
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
    update() {
      if (this.body.name) {
        this.disabledAdd = true;

        this.$http.put(`countries/${this.id}`, this.body).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            this.disabledAdd = false;
            this.$router.push('/admin/system/countries');
          },
          (err) => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
  },
  created() {
    if (!this.$checkRoles('countriesAdd')) {
      this.$router.push('/admin/');
    }
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`countries/${this.id}`).then(
        (res) => {
          this.body = res.data;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
};
</script>
